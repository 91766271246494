import React from 'react';
import { FaAward, FaReact, FaJava, FaLaravel } from 'react-icons/fa';
import { VscFolderLibrary } from 'react-icons/vsc';
import ME from '../../assets/me.jpg';
import './intro.css';
import Cert from '../../assets/OCPJSE11.png'

const Intro = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <img src={Cert} alt="Oracle Certified Programmer in Java 11 (1Z0-819)"/>
            </article>
            <article className="about__card">
              <FaJava className="about__icon" style={{marginRight: 5}}/>
              <FaReact className="about__icon" />
              <FaLaravel className="about__icon" style={{marginLeft: 5}}/>
              <h5>Full stack</h5>
              <small>Equally comfortable using Java, Laravel, React and more...</small>
            </article>
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>8+ years</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>20+ Completed Projects</small>
            </article>
          </div>
          <p>I have spent the last 8+ years in a corporate environment in various software teams. During this time, I also worked on side projects most of the time. It meant early morning coding sessions from as early as 4am till my day job started. Through my projects, I could steer my professional development better and was exposed to a much wider range of technologies.
            Besides, I have also constantly worked with entrepreneurs and got into the mindset of discussing ideas, finding solutions, working closely with founders and accurately plan ahead.
            Most of all, I loved it much more than my 9-5, so I decided to dedicate my professional life to this and make it available for those who need it the most.</p>
          <a href="#contact" className="btn btn-primary">Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default Intro