import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga";
import App from './App';
import './index.css';

ReactGA.initialize("UA-66229413-2");
ReactGA.pageview(window.location.pathname);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
