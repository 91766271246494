import React, {useEffect, useState} from 'react';
import IMG1 from '../../assets/shoptopus.jpg';
import IMG2 from '../../assets/syncbin.jpg';
import IMG3 from '../../assets/cloo.jpg';
import IMG4 from '../../assets/magyarshop.jpg';
import IMG5 from '../../assets/dtrans.jpg';
import IMG6 from '../../assets/asdaportal.jpg';
import ImageViewer from 'react-simple-image-viewer'

import './portfolio.css';

const Portfolio = () => {
  const [galleryId, setGalleryId] = useState(0)
  const [currentImage, setCurrentImage] = useState(0)
  const [images, setImages] = useState([])

  const soloProjects = [
    {
      id: 1,
      title: 'Shoptopus',
      img: IMG1,
      description:
        'Fully featured e-commerce solution',
      technologies: 'Laravel | React | Redux | Microservices | CI/CD',
      gallery: 4
    },
    {
      id: 2,
      title: 'SyncBin',
      img: IMG2,
      description:
        'Real-Time file and text sharing tool.',
      technologies: 'React | React Native | Redux | Firebase',
      link: 'https://syncbin.co.uk',
      ios: 'https://apps.apple.com/us/app/syncbin/id1629902171',
      android: 'https://play.google.com/store/apps/details?id=com.syncbin&hl=en&gl=US'
    },
    {
      id: 3,
      title: 'Cloo',
      img: IMG3,
      description: 'Outdoor puzzle game for groups.',
      technologies: 'Laravel | React | Redux | React Native | Microservices | CI/CD',
      gallery: 2,
    },
    {
      id: 4,
      title: 'Magyarshop',
      img: IMG4,
      description:
        'Custom e-commerce solution.',
      technologies: 'Laravel | React | Redux',
      link: 'https://magyarshop.co.uk',
      gallery: 6,
    },
    {
      id: 5,
      title: 'DTrans',
      img: IMG5,
      description:
        'Fully responsive interactive website built based on Adobe XD design',
      technologies: 'Laravel | VueJs',
      link: 'https://dtrans.co.uk',
      gallery: 5
    },
    {
      id: 6,
      title: 'Asda Lenstec Portal',
      img: IMG6,
      description:
        'Asda opticians accessory ordering system.',
      technologies: 'Laravel | VueJs',
      gallery: 1
    },
  ];

  useEffect(() => {
    if (galleryId !== 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
    let images = []
    switch (galleryId) {
      case 1:
        images = [
          require('../../assets/samples/samples-013.jpg'),
          require('../../assets/samples/samples-014.jpg')
        ]
        break
      case 2:
        images = [
          require('../../assets/samples/cloo1.png'),
          require('../../assets/samples/cloo2.png'),
          require('../../assets/samples/cloo3.png'),
          require('../../assets/samples/cloo4.png'),
          require('../../assets/samples/cloo5.png')
        ]
        break
      case 3:
        images = [
          require('../../assets/samples/phone_screenshot_mg.png'),
          require('../../assets/samples/phone_reports_1.jpeg'),
          require('../../assets/samples/phone_reports_1.jpeg'),
          require('../../assets/samples/macbook_screenshot_dashboard.png'),
          require('../../assets/samples/samples-007.jpg'),
          require('../../assets/samples/samples-008.jpg')
        ]
        break
      case 4:
        images = [
          require('../../assets/samples/shoptopus1.png'),
          require('../../assets/samples/shoptopus2.png'),
          require('../../assets/samples/shoptopus3.png')
        ]
        break
      case 5:
        images = [
          require('../../assets/samples/samples-009.jpg'),
          require('../../assets/samples/samples-010.jpg')
        ]
        break
      case 6:
        images = [
          require('../../assets/samples/samples-001.jpg'),
          require('../../assets/samples/samples-011.jpg'),
          require('../../assets/samples/samples-012.jpg')
        ]
        break
    }
    setImages(images.map((item) => item))
  }, [galleryId])

  // useEffect(() => {
    // console.log(images)
  // }, [images])

  const closeGallery = () => {
    setGalleryId(0)
    setCurrentImage(0)
  }

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <div className="portfolio__item-image">
              <img src={pro.img} alt={pro.title} />
            </div>
            <div className="portfolio__item-content">
              <h3 className="portfolio__project-title">{pro.title}</h3>
              {pro.link &&
                <a
                  href={pro.link}
                  target="_blank"
                  className="portfolio__project-website"
                  rel="noreferrer"
                >
                  <small>
                    {pro.link}
                  </small>
                </a>
              }
              <p>{pro.description}</p>
              <p>{pro.technologies}</p>
            </div>
            <div className="portfolio__item-cta">
              {pro.gallery && <a
                className="btn btn-primary"
                onClick={() => setGalleryId(pro.gallery)}
              >
                Gallery
              </a>}
              {pro.ios && <a
                href={pro.ios}
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
              >
                App Store
              </a>}
              {pro.android && <a
                href={pro.android}
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
              >
                Google Play
              </a>}
            </div>
            <div className="portfolio__item-cta">
            </div>
          </article>
        ))}
      </div>
      {galleryId !== 0 && images.length > 0 && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeGallery}
          backgroundStyle={{
            height: '100vh',
            position: 'fixed',
            zIndex: 100,
            backgroundColor: 'rgba(0,0,0,0.9)'
          }}
        />
      )}
    </section>
  );
};

export default Portfolio;
