import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './testimonials.css';

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      link: 'https://www.linkedin.com/in/roland-treiber/',
      name: 'Jon Somerscales - Digital Product | UX/UIDigital Product | UX/UI',
      role: 'Digital Product | UX/UIDigital Product | UX/UI',
      test: 'Roland\'s working style is flexible, efficient and innovative. He is comfortable delivering new features and prototyping with new technologies. I can trust in Roland to deliver and can also be confident that he is engineering quality code. He is continually driving forward, whether that is building products or gaining new technical skills. His appreciation for product design and passion for quality means that he would collaborate well with any technology or product team. I enjoyed collaborating with Roland to deliver something special and I hope we can do the same again in the future.',
    },
    {
      id: 2,
      link: 'https://www.linkedin.com/in/roland-treiber/',
      name: 'David Fenlon - CEO Media Exchange Group',
      role: 'CEO Media Exchange Group',
      test: "If there is an impossible logic problem to solve, Roland is your man. We had a number of issues around creating a structure from very early stage which needed whole areas of functionality created and running quickly, whilst being able to interact with restrictive 3rd party solutions. Roland was in some instances able to solve these problems single handed.\n" +
          "\n" +
          "Roland will usually come back after thinking about a problem for a few hours with a comprehensive solution and a plan to get to completion quickly. He is remarkably productive and often completes tasks ahead of schedule.\n" +
          "\n" +
          "For those looking for a developer to take on an early stage project that needs building from the bottom up, I would speak to Roland.",
    },
    {
      id: 2,
      link: 'https://www.linkedin.com/in/roland-treiber/',
      name: 'Will Squires - Technical Lead at Media Exchange Group',
      role: 'Technical Lead at Media Exchange Group',
      test: 'I knew from the moment I met Roland that we\'d work well together. I joined the company as a Senior Dev alongside Roland, and within a few weeks we\'d determined we didn\'t need a leader and we ran things in our department ourselves. \n' +
          '\n' +
          'When presented with a huge challenge, Roland\'s answer is to get stuck right in; his productivity is phenomenol, and he has that rare attribute among engineers of really wanting to fully explain and allow others the opportunity to challenge his work.\n' +
          '\n' +
          'Roland is an absolute fountain of knowledge and if you manage to find something he\'s not clued up on, he goes away, learns quickly and will quickly know basically all there is to know. When giving feedback to others Roland is always keen to ensure that it\'s treated as a learning opportunity for both the reviewer and the person being reviewed. \n' +
          '\n' +
          'I would work with Roland again in a heartbeat.',
    }
  ];
  return (
    <section id="testmonials">
      <h5>Feedback from my peers</h5>
      <h2>Testimonials</h2>
      <Swiper 
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        >
        {testimonials.map((test) => (
          <SwiperSlide className="testimonial" key={test.id}>
          <div className="client__avatar">
            <a href={test.link}>
              <BsLinkedin />
            </a>
          </div>
          <h5 className='client__name'>{test.name}</h5>
          <small className="client__review">{test.test}</small>
        </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}

export default Testimonials