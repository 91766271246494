import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import {FaGithub, FaMedium, FaYoutube} from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/roland-treiber/" target="_blank" rel="noreferrer" ><BsLinkedin /></a>
      <a href="https://github.com/thecaringdeveloper" target="_blank" rel="noreferrer" ><FaGithub /></a>
      <a href="https://thecaringdeveloper.com" target="_blank" rel="noreferrer" ><FaGlobe /></a>
      <a href="https://medium.com/@hello-85764" target="_blank" rel="noreferrer" ><FaMedium/></a>
      <a href="https://www.youtube.com/playlist?list=PLSe91q1rf5vV-gTi0zJTz1BNvnb0x426p" target="_blank" rel="noreferrer" ><FaYoutube/></a>
    </div>
  )
}

export default HeaderSocials
